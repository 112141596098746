import React, { Component, useState, useRef, useCallback, useEffect } from "react"
import { useHistory } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import TweenOne from 'rc-tween-one';
import {
    Container, Row, Col, Button, Form, Input, Label, Modal, Alert
} from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// Components
import GeniusResultDynamoDetail from './components/geniusResultDynamoDetail';
import GeniusResultBlazeDetail from './components/geniusResultBlazeDetail';
import GeniusResultTempoDetail from './components/geniusResultTempoDetail';
import GeniusResultSteelDetail from './components/geniusResultSteelDetail';
import GetFullResultDetail from './components/getFullResult';

// redux
import { useDispatch, useSelector } from "react-redux";
import { resetTesterAnswerQuestion } from "../../store/slice/testerInfoSlice";

// request
import { updateTesterInfo } from "../../utility/apiRequests";

import './result.scss'


export default function PageGeniusResult(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const getFullResultRef = useRef();
    const [isShowGetFullResult, setShowGetFullResult] = useState(false);
    const [isShowGetGeniusReportResult, setShowGetGeniusReportResult] = useState(false);
    const [isLoadingGetGeniusReport, setIsLoadingGetGeniusReport] = useState(false);
    const [inputEmailForm, setInputEmailForm] = useState('');
    const [inputNameForm, setInputNameForm] = useState('');
    const [inputTelForm, setInputTelForm] = useState('');
    const [updateTesterInforErrorAlert, setUpdateTesterInforErrorAlert] = useState('');

    const { testerCode, testGeniusResult, testGeniusInfo, testGeniusImageResult, testGeniusName } = useSelector(
        (state) => state.testerResult
    );

    const retestHandler = () => {
        dispatch(resetTesterAnswerQuestion({}));
        history.push(`/`);
    }

    const executeScroll = () => {
        const timeout = setTimeout(() => {
            getFullResultRef.current.scrollIntoView({behavior: 'smooth'});
        }, 500)

    }

    const toggleGetFullReport = () => {
        setShowGetGeniusReportResult(!isShowGetGeniusReportResult);
    }

    const updateTesterResult = () => {
        setUpdateTesterInforErrorAlert('');

        let payloadData = {
            email: inputEmailForm,
            code: testerCode,
            name: inputNameForm,
            tel: inputTelForm,
        };

        if(inputEmailForm && inputNameForm && inputTelForm){} else {
            setUpdateTesterInforErrorAlert('กรุณากรอกข้อมูลให้ครบถ้วน');
            return;
        }
        setIsLoadingGetGeniusReport(true);
        updateTesterInfo(payloadData).then(res => {
            if (res.status == 'success') {

                let reports = [res.report_file, "https://thegeniustalents.com/static/report/Report-4Genius-Parent-Kids.pdf"];

                reports.map((report, i) => {     
                    const link = document.createElement('a');
                    link.href = report;

                    link.setAttribute(
                        'download',
                        `GeniusReport.pdf`,
                    );
                    link.setAttribute('target', '_blank');
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                });

                setShowGetGeniusReportResult(false);

            }else if(res.status == 'fail'){
                /*let errorMessage = res.message;

                if(errorMessage == 'Already Use'){
                    errorMessage = 'Token นี้ได้ถูกใช้งานไปแล้ว';
                }*/
                setUpdateTesterInforErrorAlert(errorMessage);
            }
            setIsLoadingGetGeniusReport(false);
        }).catch(err => {
            console.log(err);
            setIsLoadingGetGeniusReport(false);
        });
    };

    const copyTextClick = (copyText) => {
        navigator.clipboard.writeText(copyText);
        toastr.info("คัดลอกสำเร็จ", "")
    }

    //   const { testerType, testerGeniusStats, testerAnswers } = useSelector(
    //     (state) => state.testerInfo
    //   );
    const showResultButtonStyle = {
        fontWeight: 'bold', fontSize: "36px", height: '80px', borderRadius: '30px', minWidth: '150px', margin: '5px', backgroundColor: testGeniusInfo.color, borderColor: testGeniusInfo.color
    };

    const nextResultButtonStyle = {
        backgroundColor: testGeniusInfo.color, borderColor: testGeniusInfo.color
    };

    return (
        <React.Fragment>
            <MetaTags>
                <title>The Genius Talents</title>
            </MetaTags>

            <section className="my-5">
                <Container>
                    <svg viewBox="0 0 1920 735" preserveAspectRatio="none" className="result-background">
                        <polygon points="1920,630 1067,735 0,653.3 0,358.1 377,293 778,395 1242,233 1920,0" className="result-c1" style={{ fill: testGeniusInfo.color }}></polygon>
                    </svg>

                    <Row className="justify-content-center">
                        <div className="text-center">
                            <h1 className='text-title'>คุณเป็นอัจฉริยะด้าน</h1>
                        </div>
                        <Col sm={4} className="mt-3">
                            <div className="maintenance-img">
                                <img src={testGeniusImageResult} className="img-fluid mx-auto d-block" />
                            </div>
                        </Col>
                        <div className="text-center mt-4">
                            <span className="result-title" style={{ color: testGeniusInfo.color}} >{testGeniusInfo.nameTh} </span>
                            <Button style={showResultButtonStyle} type="primary" shape="round">
                                {testGeniusResult}%
                            </Button>
                            <span className="result-title" style={{ color: testGeniusInfo.color}}>{testGeniusInfo.nameEng} </span>
                        </div>
                    </Row>
                </Container>
                <div className="text-center ">
                    <h2 className="text-title" >รหัสผลทดสอบ &nbsp;
                        <Button className="btn-rounded" outline color="dark" shape="round" onClick={() => copyTextClick("https://thegeniustalents.com/result?code=" + testerCode)}>
                            <b><i className="mdi mdi-content-copy"></i> {testerCode}</b>
                        </Button>
                    </h2>
                    <h3 className="text-title" style={{ color: 'red' }}>รหัสการทดสอบนี้สามารถบันทึกไว้ เพื่อนำมาดูผลลัพธ์ย้อนหลัง และ บทเรียนได้ในภายหลัง</h3>
                </div>
            </section>
            {/* <section className="my-5">
                <Row className="justify-content-center">
                    <Col sm={8}>
                        { testGeniusName == 'dynamo' && <GeniusResultDynamoDetail /> }
                        { testGeniusName == 'blaze' && <GeniusResultBlazeDetail /> }
                        { testGeniusName == 'tempo' && <GeniusResultTempoDetail /> }
                        { testGeniusName == 'steel' && <GeniusResultSteelDetail /> }
                    </Col>
                </Row>
            </section> */}
            <section>
                <Container className="my-5">
                <div className="text-center">
                    <h3 className='text-title'>เพื่อความเข้าใจใน Genius ของตัวเองมากขึ้น</h3>
                    <h3 className='text-title'>คุณสามารถเลือกที่ ปุ่มด้านล่าง</h3>
                    <h3 className='text-title'><b>“รับ Report Genius Test”</b> ได้ทันที มีทั้งหมด 3 รายงาน <span style={{textDecoration: "underline"}}>สำหรับ ผู้ใหญ่ เด็ก และ พ่อแม่</span></h3>
                </div>
                </Container>
                <Container className="my-5">
                <div className="text-center">
                    <h3 className='text-title'>เพียงคุณกรอกข้อมูลให้ครบถ้วน</h3>
                    <h3 className='text-title'>ระบบจะจัดส่งเล่มรายงานสำหรับ Genius ของคุณ</h3>
                    <h3 className='text-title'>พร้อมวิธีการเข้าเรียนคลาสออนไลน์</h3>
                    <h3 className='text-title'>ที่จะทำให้คุณเข้าใจใน Genius ของคุณและคนรอบข้างได้ดียิ่งขึ้น</h3>
                </div>
                </Container>
                <Container className="my-5">
                <div className="text-center">
                    <h2 className='text-title'>มูลค่า 3,500 บาท</h2>
                </div>
                </Container>
                <Container className="my-5">
                <div className="text-center">
                    <h3 className='text-title'>ซึ่ง Genius School Thailand และ GET Biz</h3>
                    <h3 className='text-title'>ยินดีมอบให้คุณ <b>ฟรี!!!</b> ทันที</h3>
                </div>
                </Container>
            </section>
            <section className="mt-5">
                <Row className="justify-content-center">
                    <Col md={3}>
                        <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">ชื่อ-สกุล *</Label>
                        <Input
                            type="text"
                            className="form-control form-control-lg"
                            id="formrow-firstname-Input"
                            placeholder="กรุณากรอก ชื่อ-สกุล *"
                            value={inputNameForm}
                            validate={{ required: { value: true } }}
                            onChange={(event) => {
                                setInputNameForm(event.target.value);
                            }}
                        />
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className="mb-3">
                            <Label htmlFor="formrow-tel-Input">เบอร์โทรศัพท์ *</Label>
                            <Input
                                type="text"
                                className="form-control form-control-lg"
                                id="formrow-tel-Input"
                                placeholder="กรุณากรอก เบอร์โทรศัพท์ *"
                                value={inputTelForm}
                                validate={{ required: { value: true } }}
                                onChange={(event) => {
                                    setInputTelForm(event.target.value);
                                }}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md={6}>
                        <div className="mb-3">
                            <Label htmlFor="formrow-email-Input">E-mail * (ใช้สำหรับรับผลการทดสอบ)</Label>
                            <Input
                                type="email"
                                className="form-control form-control-lg"
                                id="formrow-email-Input"
                                placeholder="กรุณากรอก E-mail"
                                value={inputEmailForm}
                                validate={{ required: { value: true } }}
                                onChange={(event) => {
                                    setInputEmailForm(event.target.value);
                                }}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md={10} style={{ textAlign: 'center' }}>

                        { updateTesterInforErrorAlert && <Alert color="danger" role="alert">
                                {updateTesterInforErrorAlert}
                        </Alert> }
                        <Button onClick={updateTesterResult} className="btn btn-primary w-md btn-lg action-button btn-rounded" style={nextResultButtonStyle} color="success" disabled={!inputEmailForm || !inputTelForm || !inputNameForm}>
                            {isLoadingGetGeniusReport && <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> }
                            รับ Report Genius Test
                        </Button>
                    </Col>
                </Row> 
            </section>
            <section>
                <Container className="my-5">
                <div className="text-center">
                    <h3 className='text-title'>จะดีแค่ไหน ถ้ารอบตัวคุณมีแต่คนที่เข้าใจว่า Genius คืออะไร</h3>
                    <h3 className='text-title'>และยอมรับความแตกต่างตามธรรมชาติของแต่ละคนได้อย่างเข้าใจ</h3>
                    <h3 className='text-title'>เพียงกอปปี้ลิงค์นี้ แล้วส่งต่อไปสู่คนที่คุณปรารถนาดีด้วย</h3>
                        <Button className="btn-rounded" outline color="dark" shape="round" onClick={() => copyTextClick("https://bit.ly/GeniusTalentsGST")}>
                            <b><i className="mdi mdi-content-copy"></i> https://bit.ly/GeniusTalentsGST</b>
                        </Button>
                </div>
                </Container>
            </section>
            <section className="mt-5">
                <div className="text-center">
                    <Button className="action-button btn-rounded" outline color="dark" shape="round" onClick={retestHandler}>
                        ลองทำแบบ<br />ทดสอบใหม่อีกครั้ง
                    </Button>
                    <Button className="action-button btn-rounded" style={nextResultButtonStyle} type="primary" shape="round" onClick={() => { setShowGetFullResult(true); executeScroll(); }}>
                        รับ Report ฉบับเต็ม
                        {/* <span style={{ fontSize: "16px", fontWeight: 100}}>(มีค่าใช้จ่ายเพิ่มเติม)</span> */}
                    </Button>
                </div>
            </section>
            <section className="mt-3 mb-5">
                <div className="text-center">
                    <img src="/logosvg.svg" />
                    <Button 
                        className="action-button btn-rounded" color="primary" shape="round" 
                        onClick={()=> window.open("https://geniusschoolthailand.com/course/talent-dynamics/", "_blank")}>
                    เรียนรู้ Talent Dynamics เพิ่มเติม
                    </Button>
                </div>
            </section>


            <section className="mt-5 mb-5" ref={getFullResultRef}>
                { isShowGetFullResult && <GetFullResultDetail /> }
            </section>
        </React.Fragment>
    );
}
