import { createSlice } from "@reduxjs/toolkit";

import ImageDynamo from '../../assets/images/gst/Dynamo.png'
import ImageBlaze from '../../assets/images/gst/Blaze.png'
import ImageTempo from '../../assets/images/gst/Tempo.png'
import ImageSteel from '../../assets/images/gst/Steel.png'

import ImageAccumulator from '../../assets/images/gst/Accumulator.png'
import ImageCreator from '../../assets/images/gst/Creator.png'
import ImageDealMaker from '../../assets/images/gst/DealMaker.png'
import ImageLord from '../../assets/images/gst/Lord.png'
import ImageMechanic from '../../assets/images/gst/Mechanic.png'
import ImageStar from '../../assets/images/gst/Star.png'
import ImageSupporter from '../../assets/images/gst/Supporter.png'
import ImageTrader from '../../assets/images/gst/Trader.png'

export const testResultInfoSlice = createSlice({
  name: "testresult",
  initialState: {
    testerCode: null,

    testGeniusName: "",
    testGeniusInfo: {
      nameTh: "",
      nameEng: "",
      color: "#A8CF38",
      probability_profiles: ""
    },
    testGeniusResult: null,
    testGeniusImageResult: null,

    testProfileResult: null,
    testProfileImageResult: null,
    testProfileReportResult: null,
  },
  reducers: {
    setTesterCode: (state, action) => {
      state.testerCode = action.payload;
    },

    setClearState: (state, action) => {
      state.testerCode = null;

      state.testGeniusName = "";
      state.testGeniusInfo = {
        nameTh: "",
        nameEng: "",
        color: "#A8CF38",
        probability_profiles: ""
      };
      state.testGeniusResult = null;
      state.testGeniusImageResult = null;
  
      state.testProfileResult = null;
      state.testProfileImageResult = null;
      state.testProfileReportResult = null;
    },

    setTestGeniusResultInfo: (state, action) => {
      state.testGeniusResult = action.payload.result_stat;
      state.testGeniusName = action.payload.name

      switch(action.payload.name){
        case 'dynamo': 
          state.testGeniusInfo = {
            nameTh: "นักจินตนาการ",
            nameEng: "Dynamo",
            color: "#A8CF38",
            probability_profiles: "Mechanic | Creator | Star"
          };
          state.testGeniusImageResult = ImageDynamo; 
          state.testGeniusReportResult = 'https://thegeniustalents.com/reports/Dynamo.pdf'; 
          break;
        case 'blaze': 
          state.testGeniusInfo = {
            nameTh: "นักสื่อสาร",
            nameEng: "Blaze",
            color: "#F6602E",
            probability_profiles: "Star | Supporter | Deal Maker"
          };
          state.testGeniusImageResult = ImageBlaze; 
          state.testGeniusReportResult = 'https://thegeniustalents.com/reports/Blaze.pdf'; 
          break;
        case 'tempo': 
          state.testGeniusInfo = {
            nameTh: "นักสังเกต",
            nameEng: "Tempo",
            color: "#FBBA3A",
            probability_profiles: "Deal Maker | Trader | Accumulator"
          };
          state.testGeniusImageResult = ImageTempo; 
          state.testGeniusReportResult = 'https://thegeniustalents.com/reports/Tempo.pdf'; 
          break;
        case 'steel': 
          state.testGeniusInfo = {
            nameTh: "นักวิเคราะห์",
            nameEng: "Steel",
            color: "#9c9c9c",
            probability_profiles: "Accumulator | Lord | Mechanic"
          };
          state.testGeniusImageResult = ImageSteel; 
          state.testGeniusReportResult = 'https://thegeniustalents.com/reports/Steel.pdf'; 
          break;
      }
    },
    setTestProfileResultInfo: (state, action) => {

      const profileResult = action.payload;
      const profileDivider = Math.sqrt(2);

      const dynamoValue = parseFloat(profileResult['dynamo'])/50.0;
      const blazeValue = parseFloat(profileResult['blaze'])/50.0;
      const tempoValue = parseFloat(profileResult['tempo'])/50.0;
      const steelValue = parseFloat(profileResult['steel'])/50.0;

      const summaryGenius = dynamoValue + blazeValue + tempoValue + steelValue;

      const tdResult = {
        name: profileResult['name'],
        graph: {
          creator: Math.round(dynamoValue*100.0),
          star: Math.round((dynamoValue + blazeValue)/profileDivider*100.0),
          supporter: Math.round(blazeValue*100.0),
          dealMaker: Math.round((blazeValue + tempoValue)/profileDivider*100.0),
          trader: Math.round(tempoValue*100.0),
          accumulator: Math.round((tempoValue + steelValue)/profileDivider*100.0),
          lord: Math.round(steelValue*100.0),
          mechanic: Math.round((steelValue + dynamoValue)/profileDivider*100.0)
        },
        genius: {
          dynamo: Math.round(dynamoValue/summaryGenius*100.0),
          blaze: Math.round(blazeValue/summaryGenius*100.0),
          tempo: Math.round(tempoValue/summaryGenius*100.0),
          steel: Math.round(steelValue/summaryGenius*100.0)
        },
        info: {
          color1: "#9c9c9c",
          color2: "#9c9c9c"
        }
      };

      switch(profileResult['name']){
        case 'Accumulator': 
          state.testProfileImageResult = ImageAccumulator; 
          state.testProfileReportResult = 'https://thegeniustalents.com/reports/6-Accumulator.pdf'; 
          tdResult.info.color1 = "#9c9c9c";
          tdResult.info.color2 = "#FBBA3A";
          break;
        case 'Creator': 
          state.testProfileImageResult = ImageCreator; 
          state.testProfileReportResult = 'https://thegeniustalents.com/reports/1-Creator.pdf'; 
          tdResult.info.color1 = "#A8CF38";
          tdResult.info.color2 = "#A8CF38";
          break;
        case 'Deal Maker': 
          state.testProfileImageResult = ImageDealMaker; 
          state.testProfileReportResult = 'https://thegeniustalents.com/reports/4-DealMaker.pdf'; 
          tdResult.info.color1 = "#FBBA3A";
          tdResult.info.color2 = "#F6602E";
          break;
        case 'Lord': 
          state.testProfileImageResult = ImageLord; 
          state.testProfileReportResult = 'https://thegeniustalents.com/reports/7-Lord.pdf'; 
          tdResult.info.color1 = "#9c9c9c";
          tdResult.info.color2 = "#9c9c9c";
          break;
        case 'Mechanic': 
          state.testProfileImageResult = ImageMechanic; 
          state.testProfileReportResult = 'https://thegeniustalents.com/reports/8-Mechanic.pdf'; 
          tdResult.info.color1 = "#A8CF38";
          tdResult.info.color2 = "#9c9c9c";
          break;
        case 'Star': 
          state.testProfileImageResult = ImageStar; 
          state.testProfileReportResult = 'https://thegeniustalents.com/reports/2-Star.pdf'; 
          tdResult.info.color1 = "#F6602E";
          tdResult.info.color2 = "#A8CF38";
          break;
        case 'Supporter': 
          state.testProfileImageResult = ImageSupporter; 
          state.testProfileReportResult = 'https://thegeniustalents.com/reports/3-Supporter.pdf'; 
          tdResult.info.color1 = "#F6602E";
          tdResult.info.color2 = "#F6602E";
          break;
        case 'Trader': 
          state.testProfileImageResult = ImageTrader; 
          state.testProfileReportResult = 'https://thegeniustalents.com/reports/5-Trader.pdf'; 
          tdResult.info.color1 = "#FBBA3A";
          tdResult.info.color2 = "#FBBA3A";
          break;
      }
      state.testProfileResult = tdResult
    },
  },
});

export const {
    setTestGeniusResultInfo, setTestProfileResultInfo, setTesterCode, setClearState
} = testResultInfoSlice.actions;

export default testResultInfoSlice.reducer;