import React, { Component } from "react"
import { Container, Row, Col } from "reactstrap"
import EinstienImage from '../../../assets/images/Einstien.jpg'

import './homeIntro.scss'


export default function ComponentHomeIntro(props) {

  return (
    <React.Fragment>
      <header className="mt-10">
        <h1 style={{ fontSize: 72, color: "#fff"}}>Free Genius Test</h1>
          <Row className="justify-content-center mt-5">
            <Col sm="8" style={{ zIndex: 10 }}>
              <Row>
              <Col md={2}>
            <div className="mt-4 mt-md-0">
              <img
                className="img-thumbnail rounded-circle avatar-xl"
                alt="Skote"
                src={EinstienImage}
              />
            </div>
          </Col>

          <Col md={10}>
            <div className="trademark">
              <p>Everybody is a Genius, but if you judge a fish by its ability to climb a tree, it will live its whole life believing that it is stupid.</p>
              <p>ทุกคนมีอัจฉริยภาพเฉพาะตัว ติดตัวมาตั้งแต่เกิด
              แต่ถ้าตัดสินปลา ด้วยความสามารถในการปีนต้นไม้ ปลาจะใช้ชีวิตทั้งชีวิตโดยเชื่อว่าตัวเอง "โง่"</p>
              <p>- Albert Einstein -</p>
              {/* by Genius School Thailand<sup>®</sup> */}
            </div>
          </Col>
              </Row>
            </Col>
          </Row>
          
      </header>
      
      <section className="tips" style={{ outline: "none" }}>
        


        {/* <div className="tip" style={{ outline: "none" }}>
          <img src="https://static.neris-assets.com/images/academy/explorers/icons/theory.svg" style={{ outline: "none" }} />
          <div className="text" style={{ outline: "none" }}>
            <div className="title" style={{ outline: "none" }}>
              View Detailed Results
              </div>
            <div className="subtitle" style={{ outline: "none" }}>
              Learn how your personality type influences many areas of your life.
              </div>
          </div>
        </div>
        <div className="tip" style={{ outline: "none" }}>
          <img src="https://static.neris-assets.com/images/academy/analysts/exercise.svg" style={{ outline: "none" }} />
          <div className="text" style={{ outline: "none" }}>
            <div className="title" style={{ outline: "none" }}>
              Unlock Your Potential
              </div>
            <div className="subtitle" style={{ outline: "none" }}>
              Grow into the person you want to be with your optional Premium Profile.
              </div>
          </div>
        </div>
        <div className="tip last" style={{ outline: "none" }}>
          <img src="https://static.neris-assets.com/images/academy/analysts/exercise.svg" style={{ outline: "none" }} />
          <div className="text" style={{ outline: "none" }}>
            <div className="title" style={{ outline: "none" }}>
              Unlock Your Potential
              </div>
            <div className="subtitle" style={{ outline: "none" }}>
              Grow into the person you want to be with your optional Premium Profile.
              </div>
          </div>
        </div> */}

        <div className="shape" style={{ outline: "none" }}></div>
        <div className="shadow" style={{ outline: "none" }}></div>
      </section>
      <Container>
        <Row className="justify-content-center ">
          <Col sm="8" style={{ zIndex: 10 }}>
            <div className="ratio ratio-16x9 text-center">
              <iframe allowFullScreen
              src="https://www.youtube.com/embed/xE9ywHA6KFY?autoplay=1&loop=1&rel=0"
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
