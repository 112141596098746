import React, { Component, useState, useCallback, useEffect, useRef } from "react"
import { useHistory, Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import queryString from 'query-string';
import {
    Container, Row, Col, Button, Card, Collapse, CardTitle, CardBody
} from "reactstrap";
import classnames from "classnames";
import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, Text, ResponsiveContainer } from 'recharts';
import Vimeo from '@u-wave/react-vimeo';
// redux
import { useDispatch, useSelector } from "react-redux";

// asset
import ImageDynamoHead from '../../assets/images/gst/dynamo-01-2.png'
import ImageBlazeHead from '../../assets/images/gst/blaze-02-2.png'
import ImageTempoHead from '../../assets/images/gst/tempo-03-2.png'
import ImageSteelHead from '../../assets/images/gst/steel-04-2.png'

import './result.scss'


export default function PageProfileResult(props) {
    const dispatch = useDispatch();
    const [showVdoNavTab, setShowVdoNavTab] = useState('0');
    const [vdoWidth, setVdoWidth] = useState(null);

    const divVdoContainer = useRef(null);
    
    useEffect( () => {
        if(divVdoContainer.current){
            let width  = divVdoContainer.current.offsetWidth;
            setVdoWidth(width);
            console.log(width);
        }

    }, [divVdoContainer]);

    const { testerCode, testGeniusName, testGeniusInfo, testProfileResult, testProfileImageResult, testProfileReportResult } = useSelector(
        (state) => state.testerResult
    );

    const showResultButtonStyle = {
        color: "#fff", fontWeight: 'bold', fontSize: "36px", height: '80px', borderRadius: '30px', minWidth: '150px', margin: '5px', backgroundColor: testProfileResult.info.color1, borderColor: testProfileResult.info.color1
    };

    const downloadReportButtonStyle = {
        backgroundColor: testProfileResult.info.color2, borderColor: testProfileResult.info.color2, color: "#fff"
    };

    const data = [
        {
          subject: 'Creator',
          A: testProfileResult.graph.creator,
          fullMark: 150,
        },
        {
          subject: 'Star',
          A: testProfileResult.graph.star,
          fullMark: 150,
        },
        {
          subject: 'Supporter',
          A: testProfileResult.graph.supporter,
          fullMark: 150,
        },
        {
          subject: 'Deal Maker',
          A: testProfileResult.graph.dealMaker,
          fullMark: 150,
        },
        {
          subject: 'Trader',
          A: testProfileResult.graph.trader,
          fullMark: 150,
        },
        {
          subject: 'Accumulator',
          A: testProfileResult.graph.accumulator,
          fullMark: 150,
        },
        {
          subject: 'Lord',
          A: testProfileResult.graph.lord,
          fullMark: 150,
        },
        {
          subject: 'Mechanic',
          A: testProfileResult.graph.mechanic,
          fullMark: 150,
        }
    ];

    const renderPolarAngleAxis = ({ payload, x, y, cx, cy, ...rest }) => {
        return (
            <Text
                {...rest}
                verticalAnchor="middle"
                y={y}
                x={x + (x - cx)*-1 / 4}
                // x={x}
                style={{ fontSize: '11px'}}
            >
                {payload.value}
            </Text>
        )
    }

    const VDOLIST = [
        {
            title: "Learn like a Genius",
            vimeoId: "476774174"
        },
        {
            title: "Lead like a Genius",
            vimeoId: "476774064"
        },
        {
            title: "Live like a Genius",
            vimeoId: "476773980"
        },
        {
            title: "Love like a Genius",
            vimeoId: "476773925"
        },
        {
            title: "Winning Formula",
            vimeoId: "476773821"
        },
        {
            title: "Losing Formula",
            vimeoId: "476773694"
        },
        {
            title: "Talent Dynamics Profile",
            vimeoId: "476773631"
        },
    ];

    const geniusScaleInfos = [
        {
            title: "Dynamo",
            image: ImageDynamoHead,
            scale: testProfileResult.genius.dynamo
        },
        {
            title: "Blaze",
            image: ImageBlazeHead,
            scale: testProfileResult.genius.blaze
        },
        {
            title: "Tempo",
            image: ImageTempoHead,
            scale: testProfileResult.genius.tempo
        },
        {
            title: "Steel",
            image: ImageSteelHead,
            scale: testProfileResult.genius.steel
        },
    ];

    return (
        <React.Fragment>
            <MetaTags>
                <title>The Genius Talents</title>
            </MetaTags>

            <section className="my-5">
                <Container>
                    <svg viewBox="0 0 1920 735" preserveAspectRatio="none" className="result-background">
                        <polygon points="1920,345 850,580 400,490 0,514 0,358 377,293 778,395 1242,233 1920,0" className="result-c1" style={{ fill: testProfileResult.info.color1 }}></polygon>
                        <polygon points="1920,630 1067,735 0,600 0,494 377,693 900,580 1920,305" className="result-c1" style={{ fill: testProfileResult.info.color2 }}></polygon>
                    </svg>

                    <Row className="justify-content-center">
                        <div className="text-center">
                            <span className="result-title" >Profile ของคุณ คือ </span>
                            <Button style={showResultButtonStyle} type="primary" shape="round">
                                {testProfileResult.name}
                            </Button>
                        </div>
                    </Row>
                    <Row className="justify-content-center mb-3">   
                        <Col sm={4} className="mt-3">
                            <div className="maintenance-img">
                                <img src={testProfileImageResult} className="img-fluid mx-auto d-block" />
                            </div>
                        </Col>
                        <Col sm={5} className="mt-3">
                            <Card body style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
                                <ResponsiveContainer width="100%" height={300}>
                                    <RadarChart cx="50%" cy="50%" outerRadius="70%" data={data}>
                                        <PolarGrid />
                                        <PolarAngleAxis dataKey="subject" tick={props => renderPolarAngleAxis(props)} orientation="outer" /> 
                                        <Radar name="" dataKey="A" stroke="#556ee6" fill="#556ee6" fillOpacity={0.6} />
                                    </RadarChart>
                                </ResponsiveContainer>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="justify-content-center" style={{ marginTop: 80}}>
                        {geniusScaleInfos.map((geniusScaleInfo, i) => {  
                            return (
                                <Col sm="2" className="mb-5">
                                    <Card body style={{ backgroundColor: "rgba(255, 255, 255, 0.6)",  }}>
                                        <div className="align-items-center text-center mb-3" style={{ marginTop: '-100px'}}>
                                            <img src={geniusScaleInfo.image} height="120" />
                                        </div>
                                        <div className="text-muted text-center">
                                            <h5 className="font-size-20 mb-0">{geniusScaleInfo.title}</h5>
                                            <h2><b>{geniusScaleInfo.scale} %</b></h2>
                                        </div>
                                    </Card>
                                </Col>
                            )
                        })}
                        <div className="text-center">
                            <h4><span style={{ color: 'red' }}>อย่าลืม!!</span> Capture หรือ Print Screen หน้าจอ Report ด้านบนนี้ไว้ เพราะนี่คือกราฟเฉพาะ<span style={{ color: 'red' }}>ของคุณ</span>เท่านั้น</h4>
                        </div>
                    </Row>
                    <Row>
                        <div className="text-center">
                            <a className="action-button btn btn-rounded" style={showResultButtonStyle} shape="round" rel="noreferrer" href={testProfileReportResult} target="_blank">
                                Download <br />Report ของคุณ
                            </a>
                            <a className="action-button btn btn-rounded" style={downloadReportButtonStyle} shape="round" rel="noreferrer" href="https://course.getbiz.co/talentdynamics" target="_blank">
                                เข้าสู่บทเรียน <br />Online
                            </a>
                        </div>
                    </Row>
                </Container>
            </section>
            {/* <section className="mt-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="10">
                        <Card>
                            <CardBody>

                                <h1 className="my-3 text-center">บทเรียน สำหรับ Talent Dynamics</h1>

                                <div className="accordion mt-5" ref={divVdoContainer}>
                                    {VDOLIST.map((vdoinfo, i) => {  
                                        return (
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" >
                                                    <button 
                                                        className={classnames({
                                                            'accordion-button': true,
                                                            'fw-medium': true,
                                                            collapsed: showVdoNavTab != i
                                                        })}
                                                        type="button" onClick={() => {setShowVdoNavTab(i)}} style={{ cursor: "pointer" }}
                                                    >
                                                        บทที่ {i+1} : {vdoinfo.title}
                                                    </button>
                                                </h2>

                                                <Collapse isOpen={showVdoNavTab == i} className="accordion-collapse">
                                                    <div >
                                                        {vdoWidth && <Vimeo
                                                            video={vdoinfo.vimeoId}
                                                            width={vdoWidth-2}
                                                        />}
                                                    </div>
                                                </Collapse>
                                            </div>
                                        ) 
                                    })}
                                </div>
                            </CardBody>
                        </Card>
                        </Col>
                    </Row>
                </Container>
            </section> */}
        </React.Fragment>
    );
}
