import React, { Component, useState, useCallback, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, Form, Label, Input, Button } from "reactstrap"

// redux
import { useDispatch, useSelector } from "react-redux";

import { updateTesterInfo } from '../../utility/apiRequests';


export default function ResultNeedTesterInfo(props) {

    const [inputEmailForm, setInputEmailForm] = useState('');
    const [inputNameForm, setInputNameForm] = useState('');
    const [inputTelForm, setInputTelForm] = useState('');

    const { testerCode, testGeniusInfo } = useSelector(
        (state) => state.testerResult
    );

    const updateTesterInfoSubmit = () => {
        let payloadData = {
            email: inputEmailForm,
            code: testerCode,
            name: inputNameForm,
            tel: inputTelForm,
        };
        updateTesterInfo(payloadData).then(res => {
            if (res.status == 'success') {
                window.location.reload(false);
            }
        }).catch(err => {
            console.log(err);
        });
    };

    return (
        <React.Fragment>
           <Container>
                <svg viewBox="0 0 1920 735" preserveAspectRatio="none" className="result-background">
                    <polygon points="1920,530 1720,430 1220,380 1067,235 0,653.3 0,358.1 377,293 778,395 1242,533 1920,200" className="result-c1" style={{ fill: "#556ee6" }}></polygon>
                </svg>
                <Row className="justify-content-center">
                    <Col sm="8" style={{ zIndex: 10 }}>
                        <div className="text-center my-5">
                            <h1>ขอบคุณสำหรับคำสั่งซื้อ Talent Dynamics Token</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                <Card body style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
                    <Col sm="12">
                        <Form>
                            <Row className="justify-content-center">
                                <Col sm="8" style={{ zIndex: 10 }}>
                                    <div className="text-center my-4">
                                        <h3>กรุณากรอกข้อมูลเพิ่มเติม เพื่อรับ Report ฉบับเต็ม</h3>
                                        <h3>สำหรับ รหัสทดสอบ <b>{testerCode}</b></h3>
                                        <h4 className="text-primary">* กรุณาจดรหัสทดสอบ เพื่อสามารถกลับไปดูผลได้</h4>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col md={6}>
                                    <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">ชื่อ-สกุล *</Label>
                                    <Input
                                        type="text"
                                        className="form-control form-control-lg"
                                        id="formrow-firstname-Input"
                                        placeholder="กรุณากรอก ชื่อ-สกุล *"
                                        value={inputNameForm}
                                        validate={{ required: { value: true } }}
                                        onChange={(event) => {
                                            setInputNameForm(event.target.value);
                                        }}
                                    />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="formrow-tel-Input">เบอร์โทรศัพท์ *</Label>
                                        <Input
                                            type="text"
                                            className="form-control form-control-lg"
                                            id="formrow-tel-Input"
                                            placeholder="กรุณากรอก เบอร์โทรศัพท์ *"
                                            value={inputTelForm}
                                            validate={{ required: { value: true } }}
                                            onChange={(event) => {
                                                setInputTelForm(event.target.value);
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="formrow-email-Input">E-mail * (ใช้สำหรับรับผลการทดสอบ)</Label>
                                        <Input
                                            type="email"
                                            className="form-control form-control-lg"
                                            id="formrow-email-Input"
                                            placeholder="กรุณากรอก E-mail"
                                            value={inputEmailForm}
                                            validate={{ required: { value: true } }}
                                            onChange={(event) => {
                                                setInputEmailForm(event.target.value);
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col md={6} style={{ textAlign: 'center' }}>
                                    <Button className="btn btn-primary w-md btn-lg" color="success" onClick={updateTesterInfoSubmit}>
                                        รับ Report ฉบับเต็ม
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    </Card>
                </Row>
            </Container>
        </React.Fragment>
    )
}