import axios from "axios";


const defaultUrl = "https://api.thegeniustalents.com/";
// const defaultUrl = "/api/";
// const defaultUrl = "http://tdbiz.geniusschoolthailand.com/td-new/";

// const testToken = localStorage.getItem("testToken");
// const accessKey = testToken ? testToken : ``;
const headers = {
//   Authorization: `Bearer ${accessKey}`,
  'Access-Control-Allow-Origin' : '*',
};


const getDefaultAPIConfig = (
    method,
    urlSuffix,
    data,
    header_required = true
  ) => {
    const url = defaultUrl + urlSuffix;
    let config = { method, url, data, withCredentials: false, };
    if (header_required) config["headers"] = headers;
    return config;
};

export const apiCalculateResult = async (formBody) => {
    let params = Object.assign(formBody);
    const config = getDefaultAPIConfig("post", "calculate-result.php", params);
    const { data } = await axios(config);
    return data;
};

export const apiGetResult = async (formBody) => {
    let params = Object.assign(formBody);
    const config = getDefaultAPIConfig("post", "result.php", params);
    const { data } = await axios(config);
    return data;
};

export const apiGetTestStats = async (formBody) => {
  const config = getDefaultAPIConfig("get", "test-stats.php");
  const { data } = await axios(config);
  return data;
};

export const apiLinkToken = async (formBody) => {
  let params = Object.assign(formBody);
  const config = getDefaultAPIConfig("post", "link-token.php", params);
  const { data } = await axios(config);
  return data;
};

export const apiPaymentRequestOmise = async (formBody) => {
  let params = Object.assign(formBody);
  const config = getDefaultAPIConfig("post", "omise.php", params);
  const { data } = await axios(config);
  return data;
};

export const updateTesterInfo = async (formBody) => {
    let params = Object.assign(formBody);
    const config = getDefaultAPIConfig("post", "update-tester-info.php", params);
    const { data } = await axios(config);
    return data;
};

// export const getTestStats = async () => {
//     const config = getDefaultAPIConfig("get", "test-stats.php", {});
//     const { data } = await axios(config);
//     return data;
// };
