import React, { Component } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { withRouter } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

//Import Images
import logo from "../assets/images/gst/gst-landscape-mini.png"
import logoGet from "../assets/images/c49d57c183de4f361ed1016fa6ba1eb6.svg"

class MainLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.capitalizeFirstLetter.bind(this)
  }

  capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2)
  }
  
  render() {
    return (
        <React.Fragment>

      <div className="my-3">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center">
                <Link to="/">
                  <img src={logo} alt="" height="70" />
                  <img src={logoGet} alt="" height="70" />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
        {this.props.children}
        </React.Fragment>
      )
  }
}

MainLayout.propTypes = {
  children: PropTypes.object,
  location: PropTypes.object
}

export default withRouter(MainLayout)
