import React, { Component, useEffect, useState } from "react"
import { useHistory } from 'react-router-dom';
import { Form, Row, Col, Card, Input } from "reactstrap"

// redux
import { useDispatch, useSelector } from "react-redux";

export default function InputTokenCode(props) {
    const history = useHistory();
    const [inputTokenForm, setInputTokenForm] = useState('');

    const getTestResultButton = () => {
        if(inputTokenForm){
            window.location.href = `/result?code=`+inputTokenForm;
            // history.push();
        }
    }

    return (
        <React.Fragment>
            <Card body style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
              <Row>
                <Col sm="12" style={{ zIndex: 10 }}>
                    <div className="text-center">
                      <h3>สำหรับผู้เคยทำแบบทดสอบมาแล้ว</h3>
                    </div>
                </Col>
              </Row>
              <Row>
              <Col sm="12" style={{ zIndex: 10 }}>
                <Form className="row gy-2 gx-3 align-items-center">
                  <div className="col-sm-6">
                    <Input 
                        type="text" className="form-control-lg" placeholder="กรอกรหัสทำเทส หรือ Token" 
                        onChange={(event) => {
                            setInputTokenForm(event.target.value);
                        }} />
                  </div>
                  <div className="col-sm-6">
                    <a onClick={getTestResultButton} style={{backgroundColor: "#F6602E", color: "#FFF"}} className="btn btn-lg w-md">รับ Report Version 2022</a>
                  </div>
                </Form>
                </Col>
              </Row>
              <Row>
                <Col className="mt-3">
                <h5><span style={{ color: 'red' }} >***</span> ถ้าจำรหัส (Token) ไม่ได้ กรุณาติดต่อ<br></br>
                 Line OA : <a href="#">@geniusschoolth</a> หรือคลิก <a href="http://bit.ly/LineGST" target="_blank">http://bit.ly/LineGST</a> <br></br>
                 พร้อมแจ้ง E-mail หรือ ชื่อ-นามสกุล</h5>
                </Col>
              </Row>
              
            </Card>
        </React.Fragment>
    )
}