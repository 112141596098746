import React from "react"
import { Redirect } from "react-router-dom"

import PageHome from "../pages/home/home"
import PageResult from "../pages/result/result"


const publicRoutes = [
  { path: "/result", component: PageResult },
  { path: "/", component: PageHome },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  // { path: "/", exact: true, component: () => <Redirect to="/home" /> },
]

export { publicRoutes }
