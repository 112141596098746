import { configureStore } from "@reduxjs/toolkit";

// Slice Import
import testerInfoReducer from "./slice/testerInfoSlice";
import testerResultReducer from "./slice/resultSlice";


export default configureStore({
    reducer: {
        testerInfo: testerInfoReducer,
        testerResult: testerResultReducer,
    },
});
