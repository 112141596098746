import React, { useState } from "react"

import {
    Alert
} from "reactstrap";

import { useOmise } from "use-omise";

import OMISE_PUBLIC_KEY from "../../constants/config";

import './CardScreen/cardScreen.scss';
import CardScreen from './CardScreen';

import { apiPaymentRequestOmise } from '../../utility/apiRequests';


export default function OmiseCreditCardPaymentForm(props) {
    const [paymentErrorAlert, setPaymentErrorAlert] = useState(false);
    const [isLoadingCheckPayment, setIsLoadingCheckPayment] = useState(false);
    const { loading, createTokenPromise } = useOmise({
      publicKey: OMISE_PUBLIC_KEY,
    });
  
    if (loading) return <div>Loading OmiseJS...</div>;
  
    const handleCardSubmit = async (cardInformation) => {
        setIsLoadingCheckPayment(true);
        const cardFormValues = {
            name: cardInformation.cardHolder,
            number: cardInformation.cardNumber.replace(/ /g,''),
            security_code: cardInformation.cardCvv,
            expiration_month: cardInformation.cardMonth,
            expiration_year: cardInformation.cardYear
        };

        try {
            const token = await createTokenPromise('card', cardFormValues);
            // Send the token to your server to create a charge
            const omiseRequestPayload = {
                tester_code: props.testerCode,
                omise_token: token
            };

            apiPaymentRequestOmise(omiseRequestPayload).then(res => {
                if(res["status"] == 'success'){
                    window.location.href = res['authorize_uri'];
                }
                setIsLoadingCheckPayment(false);
            }).catch(err => {
                // console.log(err);
            });
        } catch (error) {
            if(error.message){
                setPaymentErrorAlert(error.message);
            }else{
                setPaymentErrorAlert("Make a payment error.");
            }
            console.log(error);
            setIsLoadingCheckPayment(false);
            // Handle error on the UI
        }
    };

    return (
        <React.Fragment>
            <CardScreen handlePayment={handleCardSubmit} amount={3500} isLoadingCheckPayment={isLoadingCheckPayment}>
                { paymentErrorAlert && <Alert color="danger" role="alert">
                    {paymentErrorAlert}
                </Alert> }
            </CardScreen>
        </React.Fragment>
    )
}