import React, { Component, useState, useCallback, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, Form, Label, Input } from "reactstrap"

// Components
import ComponentHomeIntro from './components/homeIntro';
import ComponentQuestion from '../../components/Question/question';
import InputTokenCode from '../../components/InputTokenCode/inputTokenCode';

import TweenOne from 'rc-tween-one';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';

TweenOne.plugins.push(Children);

import { apiGetTestStats } from '../../utility/apiRequests';

import './home.scss'


export default function PageHome(props) {
  const [isFetches, setIsFetches] = useState(false);
  const [testStatsNumber, setTestStatsNumber] = useState(0);

  const initialFetch = useCallback(() => {
    apiGetTestStats().then(res => {
      if (res.status == 'success') {
        setTestStatsNumber(res.genius_num);
      }
    }).catch(err => {
      console.log(err);
    });
  }, []);


  useEffect(() => {
    if (!isFetches) initialFetch();
    return () => setIsFetches(true);
  }, [initialFetch, isFetches]);

  return (
    <React.Fragment>
      <MetaTags>
        <title>The Genius Talents</title>
      </MetaTags>

      <ComponentHomeIntro />
      <Container>
        <Row className="justify-content-center ">
          <Col sm="8" style={{ zIndex: 10 }}>
            <Card body style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
              <div className="text-center">
                <h3>จำนวนผู้ทำแบบทดสอบแล้ว</h3>
                <TweenOne
                  animation={{
                    Children: {
                      value: testStatsNumber,
                      floatLength: 0,
                      formatMoney: true,
                    },
                    duration: 3000,
                  }}
                  style={{ fontSize: 60, marginBottom: 12 }}
                >
                  0
                </TweenOne>
              </div>
              <div className='my-5'>
                <ComponentQuestion />
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center ">
          <Col sm="8" style={{ zIndex: 10 }}>
            <InputTokenCode />
          </Col>
        </Row>

      </Container>
    </React.Fragment>
  );
}
