import React, { Component, useState, useCallback, useEffect } from "react"
import { useHistory } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import queryString from 'query-string';
import {
    Container, Row, Col, Button
} from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import PageGeniusResult from './resultGenius';
import PageProfileResult from './resultProfile';
import ResultNotFound from './resultNotFound';
import ResultNeedTesterInfo from './resultNeedTesterInfo';

//Import Images
// import imgDynamo from "../../assets/images/gst/Dynamo.png"
import { apiGetResult } from '../../utility/apiRequests';

// redux
import { useDispatch, useSelector } from "react-redux";
import {
    setTesterCode, setTestGeniusResultInfo, setTestProfileResultInfo
} from "../../store/slice/resultSlice";

import './result.scss'


export default function PageResult(props) {
    const dispatch = useDispatch();
    const [isFetches, setIsFetches] = useState(false);
    const [resultStatus, setResultStatus] = useState(false);

    const { testerCode, testGeniusResult, testProfileResult } = useSelector(
        (state) => state.testerResult
    );


    const initialFetch = useCallback(() => {
        let params = queryString.parse(window.location.search);
        // toastr.options = {
        //     positionClass: "toast-top-right",
        //     showDuration: -1,
        //     hideDuration: -1,
        //     timeOut: -1,
        //     extendedTimeOut: -1,
        //     preventDuplicates: true,
        // };

        apiGetResult({ code: params.code }).then(res => {
            setResultStatus(res.status);
            if (res.status == 'success') {
                // toastr.info("", "รหัสทำเทสของคุณ คือ " + params.code);
                dispatch(setTesterCode(params.code));
                dispatch(setTestGeniusResultInfo(res.genius));

                if(res.profile){
                    dispatch(setTestProfileResultInfo(res.profile));
                }
            }else if(res.status == 'tester-info-uncomplete'){
                dispatch(setTesterCode(params.code));
            }
            // setDefaultPageChange(res);
        }).catch(err => {
            console.log(err);
        });
    }, []);


    useEffect(() => {
        if (!isFetches) initialFetch();
        return () => setIsFetches(true);
    }, [initialFetch, isFetches]);

    if(testProfileResult != null){
        return (
            <React.Fragment>
                <PageProfileResult /> 
            </React.Fragment>
        )
    }

    if(testGeniusResult != null){
        return (
            <React.Fragment>
                <PageGeniusResult />
            </React.Fragment>
        )
    }

    if(resultStatus == 'tester-info-uncomplete'){
        return (
            <React.Fragment>
                <ResultNeedTesterInfo />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <ResultNotFound />
        </React.Fragment>
    );
}
