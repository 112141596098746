import { createSlice } from "@reduxjs/toolkit";

export const testerInfoSlice = createSlice({
  name: "testerinfo",
  initialState: {
    testerType: 'adult',
    testerCurrentQuestion: 1,
    testerAnswers: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, ],
    testerGeniusStats: 0
  },
  reducers: {
    setNextQuestion: (state, action) => {
      if(state.testerCurrentQuestion < state.testerAnswers.length && state.testerAnswers[state.testerCurrentQuestion-1] != 0){
        state.testerCurrentQuestion = state.testerCurrentQuestion + 1;
      }
    },
    setPreviousQuestion: (state, action) => {
      if(state.testerCurrentQuestion > 1){
        state.testerCurrentQuestion = state.testerCurrentQuestion - 1;
      }
    },
    setTesterGeniusStats: (state, action) => {
      state.testerGeniusStats = action.payload;
    },
    setTesterType: (state, action) => {
      state.testerType = action.payload;
    },
    setTesterCurrentQuestion: (state, action) => {
      if(action.payload > 0){
        state.testerCurrentQuestion = action.payload;
      }
    },
    setTesterAnswerQuestion: (state, action) => {
      const qid = action.payload.qid;
      const ans_id = action.payload.ans_id;

      while(state.testerAnswers.length < qid){
        state.testerAnswers.push(0);
      }
      state.testerAnswers[qid-1] = ans_id;
    },
    resetTesterAnswerQuestion: (state, action) => {
      state.testerAnswers = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, ];
      state.testerCurrentQuestion = 1;
    },
  },
});

export const {
    setTesterGeniusStats, setTesterType, setTesterAnswerQuestion, setTesterCurrentQuestion, setNextQuestion, setPreviousQuestion, resetTesterAnswerQuestion
} = testerInfoSlice.actions;

export default testerInfoSlice.reducer;