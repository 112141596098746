const QUESTIONS = {
    "adult": [
        {
            "qid": "1",
            "question": "ข้อใดต่อไปนี้ที่อธิบายความเป็นตัวคุณได้ดีที่สุด",
            "choices": [
                "เป็นนักคิดสร้างสรรค์",
                "รักความสนุกสนาน",
                "เป็นคนระมัดระวังรอบคอบ",
                "เป็นคนละเอียด"
            ]
        },
        {
            "qid": "2",
            "question": "คุณคิดว่าคนอื่นมองคุณแบบไหน",
            "choices": [
                "เป็นมิตร(เป็นกันเอง)",
                "น่าเชื่อถือ",
                "ตัดสินใจได้ดี",
                "มีพลัง กระตือรือร้น"
            ]
        },
        {
            "qid": "3",
            "question": "คุณเพลิดเพลินกับข้อใดมากที่สุด",
            "choices": [
                "อยู่ภายใต้การควบคุม",
                "มีความคิดริเริ่มสร้างสรรค์",
                "ได้รับการยกย่อง ชื่นชม",
                "ได้ใช้ไหวพริบ ได้ใช้ความคิด"
            ]
        },
        {
            "qid": "4",
            "question": "เมื่อเริ่มทำกิจกรรมบางอย่างในกลุ่ม หรือทีมของคุณ คุณรู้สึกสนุกที่จะทำหน้าที่อะไรมากที่สุด",
            "choices": [
                "คนวางแผน",
                "ประสานงานติดต่อผู้คน",
                "วางแผนทีมงาน",
                "ใส่ใจรายละเอียด"
            ]
        },
        {
            "qid": "5",
            "question": "คนอื่นมักมองคุณอย่างไรเมื่อคุณอยู่ในงานสังสรรค์",
            "choices": [
                "สนุกสนานแบบเต็มเหนี่ยว",
                "ชอบเข้าสังคม ชอบพูดคุย",
                "สงบเสงี่ยม พูดน้อย",
                "มีความมั่นใจสูง"
            ]
        },
        {
            "qid": "6",
            "question": "เมื่อตกอยู่ภายใต้ความกดดันคุณพึ่งพาสิ่งใด น้อย ที่สุด",
            "choices": [
                "การทำงานอย่างหนัก",
                "ทักษะของคุณ",
                "เพื่อน หรือ คนรู้จักของคุณ",
                "ข้อมูลและความรู้ที่คุณมี"
            ]
        },
        {
            "qid": "7",
            "question": "คำใดต่อไปนี้อธิบายความเป็นคุณได้มากที่สุด",
            "choices": [
                "เงียบๆ เลือกที่จะสันโดษ",
                "ไม่ค่อยมีสมาธิ ทำสิ่งใดสิ่งหนึ่งนานๆ",
                "มีความลังเล คิดนาน แต่ยังไม่กล้าตัดสินใจ",
                "มีความอดทนต่ำ"
            ]
        },
        {
            "qid": "8",
            "question": "คุณทำอย่างไรเมื่อต้องตัดสินใจในเรื่องสำคัญ",
            "choices": [
                "ขอคำแนะนำจากเพื่อน ปรึกษาเพื่อน",
                "ดูว่าคนอื่นตัดสินใจอย่างไร",
                "พิจารณาอย่างละเอียดในทุกแง่มุม",
                "ใช้สัญชาตญาณของคุณ"
            ]
        },
        {
            "qid": "9",
            "question": "คุณไม่ชอบให้ผู้อื่นมองคุณว่าเป็นคนอย่างไร",
            "choices": [
                "อ่อนแอ",
                "น่าเบื่อ",
                "โดดเดี่ยว เดียวดาย มีเพื่อนน้อย",
                "มีความไม่น่าเชื่อถือ"
            ]
        },
        {
            "qid": "10",
            "question": "กิจกรรมใดที่คุณรู้สึกสนุกที่สุด",
            "choices": [
                "จัดเรียงเก้าอี้ จัดกลุ่มคนสำหรับการแสดง",
                "จัดการเรื่องเทคนิค เสียง สำหรับการแสดง",
                "สร้างเวทีใหม่ สำหรับการแสดง",
                "เรียนรู้วิธีการ acting สำหรับการแสดง"
            ]
        },
        {
            "qid": "11",
            "question": "เมื่อต้องทำกิจกรรมเป็นกลุ่ม คุณไม่ชอบทำอะไร",
            "choices": [
                "วางแผน",
                "จัดทีม แบ่งหน้าที่",
                "ดูแล ลงรายละเอียด",
                "พบปะคนอื่นๆ ที่ช่วยให้งานสำเร็จ"
            ]
        },
        {
            "qid": "12",
            "question": "เมื่อต้องไปเที่ยวพักผ่อนวันหยุด คุณสนุกกับเรื่องอะไรมากที่สุด",
            "choices": [
                "วางแผนการเที่ยวแต่ละวัน",
                "ค้นหาสถานที่ใหม่ๆ",
                "การได้ออกไปเจอผู้คนต่างๆ",
                "ตระเวนไปเรื่อย ทำความคุ้นเคยกับที่ใหม่ๆ"
            ]
        },
        {
            "qid": "13",
            "question": "อะไรที่คุณไม่ถนัด มากที่สุด",
            "choices": [
                "การสร้างความสัมพันธ์",
                "จดจำวันเกิดเพื่อนๆ หรือคนอื่นๆ",
                "สร้างสรรค์ความคิดใหม่ ๆ",
                "ทำให้สิ่งต่างๆ ดีขึ้น ทำงานได้ดีขึ้น"
            ]
        },
        {
            "qid": "14",
            "question": "ข้อใดต่อไปนี้ เป็นพรสวรรค์ของคุณ",
            "choices": [
                "ค้นพบหนทางที่จะพัฒนาสิ่งต่างๆ ให้ดีขึ้น",
                "ค้นพบคนที่เหมาะสม และมีศักยภาพมาร่วมงาน",
                "ต่อรองสินค้าได้ราคาที่ถูกใจที่สุด",
                "ทำตามกระบวนการ และขั้นตอนอย่างถูกต้อง"
            ]
        },
        {
            "qid": "15",
            "question": "อะไรที่สร้างความตึงเครียดในทีมงานของคุณได้มากที่สุด",
            "choices": [
                "ความแตกแยกไม่ลงรอยกัน",
                "ขาดโครงสร้างที่ชัดเจนของงาน",
                "ขาดความคืบหน้า ในตัวงาน",
                "ขาดความตื่นเต้น"
            ]
        },
        {
            "qid": "16",
            "question": "คุณชอบอะไรน้อยที่สุด",
            "choices": [
                "แขกที่ไม่ได้รับเชิญ",
                "ทำอะไรซ้ำๆ ทุกวัน",
                "คนที่ไม่เห็นด้วยกับคุณ",
                "ความสับสนวุ่นวาย"
            ]
        },
        {
            "qid": "17",
            "question": "คุณทำอะไรได้ดีที่สุด",
            "choices": [
                "รู้จักกับคนใหม่ๆ",
                "ดูแลใส่ใจผู้อื่น",
                "ทำให้เสร็จลุล่วง",
                "สร้างสรรค์สิ่งใหม่ๆ"
            ]
        },
        {
            "qid": "18",
            "question": "เมื่อต้องไปเที่ยวพักผ่อนวันหยุด คุณไม่ชอบทำอะไรมากที่สุด",
            "choices": [
                "วางแผนการเที่ยวแต่ละวัน",
                "ตระเวนไปเรื่อย ทำความคุ้นเคยกับที่ใหม่ๆ",
                "ค้นหาสถานที่ใหม่ๆ",
                "การได้ออกไปเจอผู้คนต่างๆ"
            ]
        },
        {
            "qid": "19",
            "question": "สิ่งใดทำให้คุณรู้สึกว่าประสบความสำเร็จที่สุด",
            "choices": [
                "การมองเห็นสิ่งที่คุณสร้างขึ้นเองกำลังเป็นจริง",
                "พบปะร่วมกับคนใหม่ๆ ที่ไม่น่าเชื่อว่าจะติดต่อได้",
                "การมองหาการต่อรองที่น่าตื่นตาตื่นใจ",
                "มองเห็นระบบที่ช่วยทำงาน ของคุณได้อย่างราบรื่น"
            ]
        },
        {
            "qid": "20",
            "question": "อะไรที่คนอื่น จะทำให้คุณเกิดความรำคาญมากที่สุด",
            "choices": [
                "คนที่ชอบทำลายกฎกติกา",
                "คนที่รอให้บอกว่าจะต้องทำอะไร",
                "ไม่เป็นมิตร",
                "ไม่สนใจผู้อื่น"
            ]
        },
        {
            "qid": "21",
            "question": "คนอื่นมักจะเห็นว่าคุณเป็นคนอย่างไร",
            "choices": [
                "เป็นคนน่าเชื่อถือ",
                "เป็นคนจัดแจงจัดการงานต่างๆ ได้ดี",
                "เป็นคนริเริ่มสร้างสรรค์",
                "เป็นคนมีมนุษยสัมพันธ์ดี ชอบพบปะผู้คน"
            ]
        },
        {
            "qid": "22",
            "question": "คนอื่นคิดว่าคุณเป็นคนอย่างไรน้อยที่สุด",
            "choices": [
                "เป็นคนน่าเชื่อถือ",
                "เป็นคนที่มีการจัดการที่ดี",
                "เป็นคนริเริ่มสร้างสรรค์",
                "เป็นคนมีมนุษยสัมพันธ์ดี"
            ]
        },
        {
            "qid": "23",
            "question": "คุณทำเรื่องอะไรได้ดีที่สุด",
            "choices": [
                "สร้างความสัมพันธ์ และเชื่อมโยงกับผู้อื่น",
                "ทำให้แน่ใจว่าทุกๆ คนในทีมไม่มีปัญหาอะไร",
                "หาทางพัฒนาสิ่งต่างๆให้ดีขึ้น",
                "หาไอเดียใหม่ๆ"
            ]
        },
        {
            "qid": "24",
            "question": "ภายใต้ความกดดัน คุณพึ่งพาสิ่งใดมากที่สุด",
            "choices": [
                "การค้นคว้าหาข้อมูล",
                "พรสวรรค์ของคุณ",
                "เพื่อนคนที่รู้จักของคุณ",
                "การทำงานหนัก"
            ]
        },
        {
            "qid": "25",
            "question": "หลังจบการทำโครงการคุณสนุกกับอะไรมากที่สุด",
            "choices": [
                "เลี้ยงฉลอง",
                "แสดงความยินดีกับทีมงาน",
                "ทำงานเอกสาร ให้เสร็จเรียบร้อย",
                "เริ่มโครงการใหม่ต่อทันที"
            ]
        }
    ],
    "kid": [
        {
            "qid": "1",
            "question": "เราเป็นคนแบบไหน",
            "choices": [
                "ชอบคิดสิ่งใหม่ๆ",
                "ชอบทำอะไรสนุกๆ",
                "รอบคอบ ระมัดระวัง",
                "ละเอียด"
            ]
        },
        {
            "qid": "2",
            "question": "คิดว่าเพื่อนๆ เห็นว่าเราเป็นคนอย่างไร",
            "choices": [
                "คุยสนุก",
                "จริงจัง น่าเชื่อถือ",
                "ตัดสินใจได้ดี ตัดสินใจได้ถูกต้อง",
                "คล่องแคล่ว ว่องไว"
            ]
        },
        {
            "qid": "3",
            "question": "เราชอบข้อใดมากที่สุด",
            "choices": [
                "ปฏิบัติตามกฎระเบียบ",
                "คิดทำอะไรใหม่ๆ",
                "มีแต่คนชื่นชม",
                "ได้ใช้ไหวพริบ ได้ใช้ความคิด"
            ]
        },
        {
            "qid": "4",
            "question": "เมื่อต้องทำงานกลุ่มกับเพื่อนๆ เราอยากทำหน้าที่อะไร",
            "choices": [
                "คนวางแผน",
                "รวบรวมเพื่อนๆ",
                "คอยแบ่งงาน",
                "ดูแลรายละเอียด"
            ]
        },
        {
            "qid": "5",
            "question": "เมื่ออยู่ในงานเลี้ยง งาน Party คนอื่นเห็นว่าเราเป็นคนอย่างไร",
            "choices": [
                "สนุกมาก สุดเหวี่ยง",
                "คุยเก่ง",
                "เงียบๆ พูดน้อย",
                "มั่นใจ"
            ]
        },
        {
            "qid": "6",
            "question": "เมื่อต้องทำเรื่องยากๆ หรือกดดัน เรามักจะไม่ทำสิ่งใด",
            "choices": [
                "ทำงานหนัก",
                "ใช้ความถนัด",
                "ให้เพื่อน หรือคนอื่นมาช่วย",
                "ใช้ข้อมูลและความรู้ที่มี"
            ]
        },
        {
            "qid": "7",
            "question": "ข้อไหน เป็นตัวเรามากที่สุด",
            "choices": [
                "เงียบๆ ไม่ชอบคนแปลกหน้า ไม่ชอบคนเยอะ",
                "ไม่มีสมาธิ อยู่นิ่งๆ ไม่ได้",
                "ลังเล คิดนาน ไม่กล้าตัดสินใจ",
                "ไม่ค่อยอดทน"
            ]
        },
        {
            "qid": "8",
            "question": "เราจะทำอย่างไรถ้าต้องตัดสินใจในเรื่องที่สำคัญ",
            "choices": [
                "ถามความคิดเห็นจากเพื่อนๆ",
                "รอดูความคิดเห็นคนส่วนใหญ่",
                "คิดอย่างละเอียดในทุกด้าน",
                "ใช้ความรู้สึกตามนิสัยเรา"
            ]
        },
        {
            "qid": "9",
            "question": "เราไม่ชอบให้คนอื่นมองว่าเราเป็นอย่างไร",
            "choices": [
                "เป็นคนอ่อนแอ",
                "เป็นคนน่าเบื่อ",
                "เป็นคนไม่ชอบเล่นกับใคร ชอบอยู่คนเดียว",
                "เป็นคนเชื่อไม่ได้"
            ]
        },
        {
            "qid": "10",
            "question": "กิจกรรมใดที่เรารู้สึกสนุกที่สุด",
            "choices": [
                "จัดเรียงเก้าอี้ จัดกลุ่มคนสำหรับการแสดง",
                "จัดการเรื่องเทคนิค เสียง สำหรับการแสดง",
                "สร้างเวทีใหม่ สำหรับการแสดง",
                "เรียนรู้วิธีการ acting สำหรับการแสดง"
            ]
        },
        {
            "qid": "11",
            "question": "เมื่อต้องทำกิจกรรมเป็นกลุ่ม เราไม่ชอบทำอะไร",
            "choices": [
                "วางแผน",
                "จัดแจงทีมงานและผู้เล่น",
                "ลงรายละเอียดในงาน",
                "พบปะคนอื่นๆ ที่ช่วยให้งานสำเร็จ"
            ]
        },
        {
            "qid": "12",
            "question": "เมื่อต้องไปเที่ยวพักผ่อนวันหยุด เราสนุกกับเรื่องอะไรมากที่สุด",
            "choices": [
                "คิดว่าแต่ละวันจะไปที่ไหน ทำอะไรบ้าง",
                "คิดหาที่เที่ยวใหม่ๆ",
                "ได้เพื่อนใหม่",
                "ทำความคุ้นเคยกับสถานที่ใหม่ๆ"
            ]
        },
        {
            "qid": "13",
            "question": "อะไรที่เราไม่ชอบทำมากที่สุด",
            "choices": [
                "การพบปะเพื่อนใหม่",
                "จดจำวันเกิดเพื่อนๆ หรือคนอื่นๆ",
                "การเสนอไอเดียใหม่ๆ",
                "ทำสิ่งที่มีอยู่แล้วให้ดีขึ้น"
            ]
        },
        {
            "qid": "14",
            "question": "ข้อใดต่อไปนี้ เป็นพรสวรรค์ของเรา",
            "choices": [
                "หาทางทำสิ่งต่างๆ ให้ดีขึ้น",
                "คบหาคนเก่งๆ คนดีๆ คนที่คอยช่วยเหลือเราได้",
                "ทำให้ทุกคนพอใจ ไม่ทะเลาะกัน",
                "ทำอะไรตามขั้นตอน"
            ]
        },
        {
            "qid": "15",
            "question": "เมื่อต้องทำงานเป็นกลุ่ม อะไรที่สร้างความตึงเครียดให้เราได้มากที่สุด",
            "choices": [
                "คนในกลุ่มเถียงกัน ไม่ชอบกัน",
                "ไม่รู้ว่าจะเริ่มทำอะไรก่อนหลัง ไม่มีขั้นตอนการทำงาน",
                "ใกล้ส่งงานแล้ว แต่งานยังไม่คืบหน้าเลย",
                "ไม่ตื่นเต้น"
            ]
        },
        {
            "qid": "16",
            "question": "เราชอบอะไรน้อยที่สุด",
            "choices": [
                "คนแปลกหน้า",
                "ทำอะไรซ้ำๆ ทุกวัน",
                "คนที่ชอบเถียง หรือคิดไม่เหมือนเรา",
                "ความยุ่งเหยิงสับสน"
            ]
        },
        {
            "qid": "17",
            "question": "เราทำอะไรได้ดีที่สุด",
            "choices": [
                "รู้จักเพื่อนใหม่",
                "ดูแลผู้อื่น",
                "ทำอะไรให้เสร็จเรียบร้อย",
                "สร้างสรรค์สิ่งใหม่ๆ"
            ]
        },
        {
            "qid": "18",
            "question": "เมื่อต้องไปเที่ยวพักผ่อนวันหยุด เราไม่ชอบทำอะไรมากที่สุด",
            "choices": [
                "คิดว่าแต่ละวันจะไปที่ไหน ทำอะไรบ้าง",
                "ทำความคุ้นเคยกับสถานที่ใหม่ๆ",
                "คิดหาที่เที่ยวใหม่ๆ",
                "ได้เพื่อนใหม่"
            ]
        },
        {
            "qid": "19",
            "question": "อะไรที่ทำให้เรารู้สึกว่า \"เราทำได้\" \"เรามีความสามารถ\"",
            "choices": [
                "สิ่งที่เราคิดและเริ่มทำ กลายเป็นจริงขึ้นมา",
                "ได้เจอกับคนที่สุดยอด เก่งมากๆ ดังมากๆ ในสายตาเรา",
                "สามารถพูดคุยจนทำให้เกิดข้อแลกเปลี่ยนที่น่าตื่นตาตื่นใจ",
                "ขั้นตอนการทำงานของเราราบรื่น เหมือนเข็มนาฬิกาที่เดินไปเรื่อยๆ อัตโนมัติโดยไม่มีใครสั่ง"
            ]
        },
        {
            "qid": "20",
            "question": "เราจะรำคาญคนแบบไหน",
            "choices": [
                "คนไม่ทำตามกฎระเบียบ",
                "คนที่คิดเองไม่เป็น ต้องรอคำสั่ง",
                "คนที่ดูไม่เป็นมิตร หยิ่ง",
                "คนที่ไม่เคยใส่ใจคนอื่นเลย"
            ]
        },
        {
            "qid": "21",
            "question": "เพื่อนๆ มักจะเห็นว่าเราเป็นคนแบบไหน",
            "choices": [
                "น่าเชื่อถือ",
                "จัดแจงจัดการงานต่างๆ ได้ดี",
                "มีความคิดสร้างสรรค์",
                "คุยสนุก เพื่อนเยอะ"
            ]
        },
        {
            "qid": "22",
            "question": "เพื่อนๆ มักจะ \"ไม่\" เห็นว่าเราเป็นคนแบบไหน",
            "choices": [
                "น่าเชื่อถือ",
                "จัดแจงจัดการงานต่างๆ ได้ดี",
                "มีความคิดสร้างสรรค์",
                "คุยสนุก เพื่อนเยอะ"
            ]
        },
        {
            "qid": "23",
            "question": "สิ่งที่เราทำได้ดีที่สุด คือ...",
            "choices": [
                "สร้างความสนิทสนมกับคนอื่น",
                "ดูแลให้ทุกคน OK",
                "ทำให้สิ่งต่างๆ ดียิ่งขึ้น",
                "คิดหาอะไรใหม่ๆ ทำอยู่เสมอ"
            ]
        },
        {
            "qid": "24",
            "question": "เวลาทำงานที่กดดัน เรามักจะใช้...",
            "choices": [
                "การค้นคว้าหาข้อมูล",
                "พรสวรรค์ หรือทักษะที่มี",
                "ความช่วยเหลือจากเพื่อน",
                "ความอดทน"
            ]
        },
        {
            "qid": "25",
            "question": "หลังจากทำงานกลุ่มเสร็จแล้ว เราอยากทำอะไรต่อมากที่สุด",
            "choices": [
                "ฉลอง",
                "แสดงความยินดีกับเพื่อนๆ ที่ทำงานด้วยกัน",
                "เก็บรายละเอียดให้เรียบร้อย",
                "หาเรื่องใหม่มาทำต่อไป"
            ]
        }
    ]
};

export default QUESTIONS;