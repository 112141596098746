import React, { Component, useState, useCallback, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, Form, Label, Input } from "reactstrap"

// Components
import ComponentQuestion from '../../components/Question/question';
import InputTokenCode from '../../components/InputTokenCode/inputTokenCode';

export default function ResultNotFound(props) {

    return (
        <React.Fragment>
           <Container>
                <svg viewBox="0 0 1920 735" preserveAspectRatio="none" className="result-background">
                    <polygon points="1920,530 1720,430 1220,380 1067,235 0,653.3 0,358.1 377,293 778,395 1242,533 1920,200" className="result-c1" style={{ fill: "#556ee6" }}></polygon>
                </svg>
                <Row className="justify-content-center">
                    <Col sm="8" style={{ zIndex: 10 }}>
                    <div className="text-center my-5">
                        <h1>ไม่พบรหัสทำเทส / Token</h1>
                    </div>
                    </Col>
                </Row>
                <Row className="justify-content-center ">
                    <Col sm="8" style={{ zIndex: 10 }}>
                    <InputTokenCode />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col sm="8" style={{ zIndex: 10 }}>
                    <div className="text-center mb-4">
                        <h1>หรือ</h1>
                    </div>
                    </Col>
                </Row>
                <Row className="justify-content-center ">
                    <Col sm="8" style={{ zIndex: 10 }}>

                        <Card body style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
                        <div className="text-center">
                            <h3>เริ่มทำแบบทดสอบ</h3>
                            
                        </div>
                        <div className='my-5'>
                            <ComponentQuestion />
                        </div>
                        </Card>
                    </Col>
                </Row>

            </Container>
        </React.Fragment>
    )
}