import React, { useState } from "react"
import {
    Alert, Row, Col, Button, Nav, NavItem, NavLink, TabPane, TabContent,
    Card,
    CardBody,
    CardTitle,
    Label,
    Form,
    Input,
} from "reactstrap";

import classnames from "classnames";

// redux
import { useDispatch, useSelector } from "react-redux";


// component
import OmiseCreditCardPaymentForm from '../../../components/Payments/omiseCreditCardPayment';
import OmisePromptPayPaymentForm from '../../../components/Payments/omisePromptPayPayment';
import InputTokenCode from '../../../components/InputTokenCode/inputTokenCode';

import { apiLinkToken } from '../../../utility/apiRequests';

import ImageSampleGraph from '../../../assets/images/gst/8-profile-01.jpg';
import ImageTalentDynamics from '../../../assets/images/gst/TalentDynamics8Profiles2-01.jpeg';
import ImageTDGraph from '../../../assets/images/gst/td-graph-1.jpg';

import "react-datepicker/dist/react-datepicker.css";
import "flatpickr/dist/themes/material_blue.css";


export default function GetFullResultDetail(props) {
    const [paymentAction, setPaymentAction] = useState('token');
    const [inputTokenForm, setInputTokenForm] = useState('');
    const [inputEmailForm, setInputEmailForm] = useState('');
    const [inputNameForm, setInputNameForm] = useState('');
    const [inputTelForm, setInputTelForm] = useState('');
    const [isLoadingGetToken, setIsLoadingGetToken] = useState(false);
    const [linkTokenErrorAlert, setLinkTokenErrorAlert] = useState('');

    const { testerCode, testGeniusInfo } = useSelector(
        (state) => state.testerResult
    );

    const updateLinkToken = () => {
        setLinkTokenErrorAlert('');

        let payloadData = {
            email: inputEmailForm,
            code: testerCode,
            token: inputTokenForm,
            name: inputNameForm,
            tel: inputTelForm,
        };

        if(inputEmailForm && inputTokenForm && inputNameForm && inputTelForm){} else {
            setLinkTokenErrorAlert('กรุณากรอกข้อมูลให้ครบถ้วน');
            return;
        }
        setIsLoadingGetToken(true);
        apiLinkToken(payloadData).then(res => {
            if (res.status == 'success') {
                window.location.href = `/result?code=`+inputTokenForm;
            }else if(res.status == 'fail'){
                let errorMessage = res.message;

                if(errorMessage == 'Already Use'){
                    errorMessage = 'Token นี้ได้ถูกใช้งานไปแล้ว';
                }
                setLinkTokenErrorAlert(errorMessage);
            }
            setIsLoadingGetToken(false);
        }).catch(err => {
            console.log(err);
            setIsLoadingGetToken(false);
        });
    };

    // const nextResultButtonStyle = {
    //     lineHeight: '30px', fontWeight: 'bold', fontSize: "26px", height: '80px', borderRadius: '30px', minWidth: '250px', margin: '5px', backgroundColor: testGeniusInfo.color, borderColor: testGeniusInfo.color
    // };

    return (
        <React.Fragment>
            {/* <section className="mt-5">
                <div className="text-center">
                    <h1 className='text-title'>จริงๆ แล้วคุณอาจจะเป็น</h1>
                    <h2 className='text-title'>{ testGeniusInfo.probability_profiles }</h2>
                </div>
            </section> */}
            <section>
                <Row className="justify-content-center ">
                    <Col sm="8" >
                    <h2 className="text-center text-title" >ทุกคนมีอัจฉริยะทั้ง 4 ด้านในตัวเอง</h2>
                    <h3 className="mt-3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;แต่จะมีด้านที่โดดเด่นเพียงหนึ่งด้าน หรือบางคนอาจมีการผสมกันระหว่างอัจฉริยะด้านเด่น 2 ด้านก็ได้ ถ้าอยากเข้าใจตัวคุณเองแบบลงลึกไปอีก สามารถรับ Report ในแบบเฉพาะของคุณ พร้อมรับกราฟที่บอกอัจฉริยะแแต่ละด้านของคุณ</h3>
                    </Col>
                </Row>
                <Row className="justify-content-center ">
                    <Col sm="8" style={{ zIndex: 10 }}>
                        <div className="ratio ratio-16x9 text-center">
                        <iframe allowFullScreen
                        src="https://www.youtube.com/embed/2YcuoDARz1o?autoplay=1&loop=1&rel=0"
                        ></iframe>
                        </div>
                    </Col>
                </Row>
            </section>
            <section className="mt-5">
                <Row className="justify-content-center">
                    <Col sm={4} >
                        <div className="maintenance-img">
                            <img src={ImageSampleGraph} className="img-fluid mx-auto d-block" />
                        </div>
                    </Col>
                    <Col sm={4} >
                        <div className="maintenance-img">
                            <img src={ImageTDGraph} className="img-fluid mx-auto d-block" />
                        </div>
                    </Col>
                </Row>
                <Row className="mt-5 justify-content-center text-center">
                    <Col sm={8} >
                        <div className="maintenance-img">
                            <img src={ImageTalentDynamics} className="img-fluid mx-auto d-block" />
                        </div>
                    </Col>

                    {/* <Col sm={8} >
                        <h2 className='text-title text-center'>รับผล Taletnt Dynamics 8 Profiles ของคุณ</h2>
                        <h2 className='text-title text-center'>พร้อมบทเรียนพิเศษ</h2>
                        <h3 className='mt-4'>1. Talent Dynamics Token มูลค่า 3,900 บาท</h3>
                        <h3 className='mt-4'>2. Report ฉบับเต็ม 3 รูปแบบ มูลค่า 9,900 บาท</h3>
                        <h4 className='mt-4'>- สำหรับเด็ก : Report สำหรับการเรียนรู้</h4>
                        <h4 className='mt-4'>- สำหรับผู้ใหญ่ : Report สำหรับการพัฒนาตัวเอง</h4>
                        <h4 className='mt-4'>- สำหรับพ่อแม่ : Report สำหรับการส่งเสริมพัฒนาลูก</h4>
                        <h3 className='mt-4'>3. คอร์สออนไลน์ Talent Dynamics มูลค่า 3,950 บาท</h3>
                        <h2 className='text-title text-center mt-5'>รวมมูลค่าที่คุณจะได้รับทั้งหมด 17,750 บาท ด้วยการชำระเพียง <span style={{ color: 'red' }} >3,500 บาท เท่านั้น!!!</span></h2>
                    </Col> */}
                </Row>
            </section>
            <section className="mt-5">
                <Row className="justify-content-center">
                    <Col sm={8} className="checkout-tabs">
                        <Row>
                            <Col lg="3" sm="4">
                            <Nav className="flex-column" pills>
                                <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: paymentAction === "token",
                                    })}
                                    onClick={() => {
                                        setPaymentAction("token")
                                    }}
                                >
                                    <i className="bx bx-badge-check d-block check-nav-icon mt-4 mb-2" />
                                    <p className="font-weight-bold mb-4">มี Token แล้ว</p>
                                </NavLink>
                                </NavItem>
                                <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: paymentAction === "omise-creditcard",
                                    })}
                                    onClick={() => {
                                        setPaymentAction("omise-creditcard")
                                    }}
                                >
                                    <i className="fab fa-cc-mastercard d-block check-nav-icon mt-4 mb-2" />
                                    <p className="font-weight-bold mb-4">ชำระเงิน บัตรเครดิต</p>
                                </NavLink>
                                </NavItem>
                                <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: paymentAction === "omise-promptpay",
                                    })}
                                    onClick={() => {
                                        setPaymentAction("omise-promptpay")
                                    }}
                                >
                                <i className="bx bx-money d-block check-nav-icon mt-4 mb-2" />
                                    <p className="font-weight-bold mb-4">ชำระเงิน พร้อมเพย์</p>
                                </NavLink>
                                </NavItem>
                            </Nav>
                            </Col>
                            <Col lg="9" sm="8">
                                <Card>
                                    <CardBody>
                                        <TabContent activeTab={paymentAction}>
                                            <TabPane tabId="token">
                                                <div>
                                                    <CardTitle className="h4">สำหรับผู้ที่มี Token แล้ว</CardTitle>
                                                    <p className="card-title-desc">
                                                        กรุณากรอกข้อมูลเพิ่มเติม
                                                    </p>
                                                    <Form>
                                    <Row>
                                        <Col md={6}>
                                            <div className="mb-3">
                                            <Label htmlFor="formrow-firstname-Input">ชื่อ-สกุล *</Label>
                                            <Input
                                                type="text"
                                                className="form-control form-control-lg"
                                                id="formrow-firstname-Input"
                                                placeholder="กรุณากรอก ชื่อ-สกุล *"
                                                value={inputNameForm}
                                                validate={{ required: { value: true } }}
                                                onChange={(event) => {
                                                    setInputNameForm(event.target.value);
                                                }}
                                            />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-tel-Input">เบอร์โทรศัพท์ *</Label>
                                                <Input
                                                    type="text"
                                                    className="form-control form-control-lg"
                                                    id="formrow-tel-Input"
                                                    placeholder="กรุณากรอก เบอร์โทรศัพท์ *"
                                                    value={inputTelForm}
                                                    validate={{ required: { value: true } }}
                                                    onChange={(event) => {
                                                        setInputTelForm(event.target.value);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-email-Input">E-mail * (ใช้สำหรับรับผลการทดสอบ)</Label>
                                                <Input
                                                    type="email"
                                                    className="form-control form-control-lg"
                                                    id="formrow-email-Input"
                                                    placeholder="กรุณากรอก E-mail"
                                                    value={inputEmailForm}
                                                    validate={{ required: { value: true } }}
                                                    onChange={(event) => {
                                                        setInputEmailForm(event.target.value);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-token-Input">Token *</Label>
                                                <Input
                                                    type="text"
                                                    className="form-control form-control-lg"
                                                    id="formrow-token-Input"
                                                    placeholder="กรุณากรอก Token *"
                                                    value={inputTokenForm}
                                                    validate={{ required: { value: true } }}
                                                    onChange={(event) => {
                                                        setInputTokenForm(event.target.value);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>

                                    { linkTokenErrorAlert && <Alert color="danger" role="alert">
                                        {linkTokenErrorAlert}
                                    </Alert> }
                                    <div style={{ textAlign: 'right' }}>
                                        <Button className="btn btn-primary w-md btn-lg" color="success" onClick={updateLinkToken}>
                                            {isLoadingGetToken && <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> }
                                            รับ Report ฉบับเต็ม
                                        </Button>
                                    </div>

                                </Form>
                                                </div>
                                    
                                    <hr></hr>
                                    <Row className="justify-content-center mt-2">
                                        <Col style={{ zIndex: 10 }}>
                                            <InputTokenCode />
                                        </Col>
                                    </Row>
                                            </TabPane>
                                        </TabContent>
                                        <TabContent activeTab={paymentAction}>
                                            <TabPane tabId="omise-creditcard">
                                                <div>
                                                    <CardTitle className="h4">ชำระเงิน บัตรเครดิต</CardTitle>
                                                    <OmiseCreditCardPaymentForm testerCode={testerCode} />
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                        <TabContent activeTab={paymentAction}>
                                            <TabPane tabId="omise-promptpay">
                                                <div>
                                                    <CardTitle className="h4">ชำระเงิน พร้อมเพย์</CardTitle>
                                                    <OmisePromptPayPaymentForm testerCode={testerCode} />
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </section>
        </React.Fragment>
    );
}
