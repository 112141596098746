import React, { Component, useEffect, useState } from "react"
import { useHistory } from 'react-router-dom';
import classnames from "classnames"
import QueueAnim from 'rc-queue-anim';
import queryString from 'query-string';
import { Row, Col, Nav, NavItem, NavLink, Progress, Modal, Button } from "reactstrap"

import QUESTIONS from '../../constants/testing';

import { apiCalculateResult } from '../../utility/apiRequests';

// redux
import { useDispatch, useSelector } from "react-redux";
import {
    setTesterType, setTesterAnswerQuestion, setPreviousQuestion, setNextQuestion
} from "../../store/slice/testerInfoSlice";
import {
    setClearState
} from "../../store/slice/resultSlice";

import './question.scss'


export default function ComponentQuestion(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { testerType, testerGeniusStats, testerAnswers, testerCurrentQuestion } = useSelector(
        (state) => state.testerInfo
    );

    const [isShowAgreement, setShowAgreement] = useState(false);
    const [isReadAgreement, setReadAgreement] = useState(false);

    const nextPrevioutButtonStyle = { 
        fontSize: "26px", height: '60px', borderRadius: '60px', minWidth: '200px', margin: '5px', backgroundColor: "#556ee6", borderColor: "#556ee6"
    };

    const submitResultButtonStyle = {
        fontSize: "26px", height: '60px', borderRadius: '60px', minWidth: '200px', margin: '5px', backgroundColor: "#d9534f", borderColor: "#d9534f"
    };

    useEffect(() => {
        if(testerCurrentQuestion < QUESTIONS[testerType].length){
            dispatch(setNextQuestion());
        }
        
        return () => {
        //    console.log('Prev value', testerAnswers) 
        }
   
    }, [testerAnswers])

    const submitResult = () => {
        if(testerCurrentQuestion >= QUESTIONS[testerType].length){
            let params = queryString.parse(window.location.search);
            const toCalculatePayload = {
                answers: testerAnswers,
                type: testerType,
                lpcode: params.lpcode
            };

            apiCalculateResult(toCalculatePayload).then(res => {
                if(res["status"] == 'success'){
                    history.push(`/result?code=`+res["code"]);
                }
            }).catch(err => {
                // console.log(err);
            });
        }
    }

    //   const ansQuestionId = 1;

    const nextQuestion = () => {
        dispatch(setNextQuestion());
    }

    const previousQuestion = () => {
        dispatch(setPreviousQuestion());
    }

    const toggleTesterType = (tab) => {

        const is_answered = testerAnswers.reduce((total, currentValue) => total = total + currentValue,0);

        if (testerType !== tab && is_answered == 0) {
            dispatch(setTesterType(tab));
        }
    }

    const handleAnswerButtonClick = (e) => {
        const is_answered = testerAnswers.reduce((total, currentValue) => total = total + currentValue,0);
        
        if(is_answered == 0 && !isReadAgreement){
            setShowAgreement(true);
            return;
        }

        let selects = e.target.value.split("-");
        const answerQuestion = {
            qid: selects[0],
            ans_id: selects[1]
        };
        dispatch(setTesterAnswerQuestion(answerQuestion));
        dispatch(setClearState({}));
    };

    const questionItems = QUESTIONS[testerType].map(question => {
        return (
            <QueueAnim key={question.qid} type={['right', 'left']} ease={['easeOutQuart', 'easeInOutQuart']}>
                {testerCurrentQuestion == question.qid ?
                    <React.Fragment>
                        <Row key={"question-title-"+question.qid} className="my-2" style={{ paddingTop: 20 }}>
                            <Col sm="12">
                                <h2><b>{question.qid}. {question.question}</b></h2>
                            </Col>
                        </Row>
                        <Row key={"question-choices-"+question.qid}>
                            <Col className="funkyradio gutter-row" sm="12" style={{ fontSize: 18 }} >
                                {question.choices.map((choice, index) => {
                                    return (
                                        <div className="funkyradio-primary" key={"question-" + question.qid + "-choice-" + (index + 1)}>
                                            <input checked={testerAnswers[question.qid-1] == index + 1} type="radio" name={"ans-question-" + question.qid} id={"question-" + question.qid + "-choice-" + (index + 1)} value={question.qid + "-" + (index + 1)} onChange={handleAnswerButtonClick} required="" />
                                            <label htmlFor={"question-" + question.qid + "-choice-" + (index + 1)}>{choice}</label>
                                        </div>
                                    )
                                })}
                            </Col>
                        </Row>
                    </React.Fragment>
                    : null}
                </QueueAnim>
        )
    })

    return (
        <React.Fragment>
            {/*<div className="d-flex flex-wrap gap-2">
                <div
                    className="btn-group"
                    role="group"
                >
                <Button 
                    style={{ cursor: "pointer", fontSize: 30, minHeight: '85px', verticalAlign: 'middle', display: 'table-cell' }}
                    className={classnames({
                        active: testerType === "adult",
                        hideSelectTestTypeButton: testerAnswers.reduce((total, currentValue) => total = total + currentValue,0)
                    })}
                    onClick={() => {
                        toggleTesterType("adult")
                    }}
                >Left</Button>
                <Button 
                    style={{ cursor: "pointer", fontSize: 30, minHeight: '85px', verticalAlign: 'middle', display: 'table-cell' }}
                    className={classnames({
                        active: testerType === "kid",
                        hideSelectTestTypeButton: testerAnswers.reduce((total, currentValue) => total = total + currentValue,0)
                    })}
                    onClick={() => {
                        toggleTesterType("kid")
                    }}
                >Middle</Button>
                </div>
            </div> */}

            <Nav pills className="navtab-bg nav-justified icon-tab">
                <NavItem style={{ display: 'table' }}>
                    <NavLink
                        style={{ cursor: "pointer", fontSize: 30, minHeight: '85px', verticalAlign: 'middle', display: 'table-cell' }}
                        className={classnames({
                            active: testerType === "adult",
                            hideSelectTestTypeButton: testerAnswers.reduce((total, currentValue) => total = total + currentValue,0)
                        })}
                        onClick={() => {
                            toggleTesterType("adult")
                        }}
                    >
                        สำหรับ ผู้ใหญ่ / พ่อแม่
                </NavLink>
                </NavItem>
                <NavItem style={{ display: 'table' }}>
                    <NavLink
                        style={{ cursor: "pointer", fontSize: 30, minHeight: '85px', verticalAlign: 'middle', display: 'table-cell' }}
                        className={classnames({
                            active: testerType === "kid",
                            hideSelectTestTypeButton: testerAnswers.reduce((total, currentValue) => total = total + currentValue,0)
                        })}
                        onClick={() => {
                            toggleTesterType("kid")
                        }}
                    >
                        <div>สำหรับ เด็ก </div><div style={{ fontSize: 16}}>(อายุ 8-18 ปี)</div>
                </NavLink>
                </NavItem>
            </Nav>
            <Row className="my-3">
                <Col span={24}>
                    <div className="animated-progess">
                        <Progress
                            value={testerCurrentQuestion/QUESTIONS[testerType].length*100}
                            color="warning"
                            className="progress-xl"
                        ></Progress>
                    </div>
                </Col>
            </Row>
                {questionItems}
            <Row >
              <Col className="submitchoice text-left" span={12} >
                  {testerCurrentQuestion > 1 && <Button style={nextPrevioutButtonStyle} shape="round" onClick={previousQuestion} >
                      ย้อนกลับ
                  </Button>}
              </Col>
              <Col className="submitchoice text-right" span={12} >
                  {testerCurrentQuestion < QUESTIONS[testerType].length && <Button style={nextPrevioutButtonStyle} type="primary" shape="round" onClick={nextQuestion} disabled={testerAnswers[testerCurrentQuestion-1] == 0}>
                      ถัดไป
                  </Button>}

                  {testerCurrentQuestion == QUESTIONS[testerType].length && <Button style={submitResultButtonStyle} type="primary" shape="round" onClick={submitResult} disabled={testerAnswers[testerCurrentQuestion-1] == 0}>
                      ดูผลทดสอบ
                  </Button>}
              </Col>
            </Row>
            <Modal
                isOpen={isShowAgreement}
                size="lg"
            >
                <div className="modal-header">
                    <h2 className="modal-title mt-0" id="myModalLabel">
                    #ข้อควรระวังและคำแนะนำ
                    </h2>
                    <button
                    type="button"
                    onClick={() =>
                        setShowAgreement(false)
                    }
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    >
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <ul style={{ textAlign: 'left !important', fontSize: '16pt' }}>
                        <li>กรุณาอ่านคำถามภาษาไทยให้รอบคอบก่อนทำแบบทดสอบแต่ละข้อ</li>
                        <li>คุณควรตอบคำถามจาก ความรู้สึก <span style={{ color: 'red' }} >{ '"ชอบ"' }</span> ที่เป็นตัวเองที่สุด</li>
                        <li>ไม่ตอบตามความอยากที่จะเป็นในอนาคต หรืออิงกับอดีตที่ผ่านมา</li>
                        <li>ควรเลือกคำตอบแรกที่เข้ามาในใจเสมอ และอย่าใช้เวลานาน</li>
                    </ul>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-primary btn-lg"
                        onClick={() => {
                            setShowAgreement(false);
                            setReadAgreement(true);
                        }}
                    >
                    ตกลง
                    </button>
                </div>
            </Modal>
        </React.Fragment>
    );
}
